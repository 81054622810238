import { FC, ReactNode } from 'react'
import cx from 'classnames'

export const StructuredList: FC<{
	children: ReactNode
	className?: string
}> = ({ children, className }) => {
	return (
		<div className={cx(className)}>
			<ul className="custom-list">{children}</ul>
		</div>
	)
}

import Blades from '@/components/BladeResolver'
import { Hero } from '@/components/Blades/Hero'
import { BrandLine } from '@/components/UI/BrandLine'
import { StructuredList } from '@/components/UI/StructuredList'
import { Text } from '@/components/UI/TextCore'
import { Title } from '@/components/UI/TitleCore'
import { useMenuState } from '@/context'
import { Layout } from '@/hoc/Layout'
import { BaseComponentProps, MetadataProps } from '@/types'
import {
	isHeading,
	isList,
	isParagraph,
	renderRule,
} from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import { FC, useEffect } from 'react'
import { renderNodeRule, StructuredText } from 'react-datocms'

interface PolicyTemplateProps extends BaseComponentProps {
	data: {
		policy: {
			__typename: string
			metadata: MetadataProps
			id: string
			slug: string
			pageName: string
		}
	}
}

const PolicyTemplate: FC<PolicyTemplateProps> = ({ data }) => {
	const { setMenuOpen } = useMenuState()

	useEffect(() => {
		setMenuOpen(false)

		return () => {
			setMenuOpen(false)
		}
	}, [setMenuOpen])

	return (
		<Layout locale={`en`}>
			<div className="bg-white">
				<Hero {...data.policy.hero[0]} />

				<div className="py-24">
					<div className="max-w-4xl mx-auto w-11/12 mb-12">
						<Title.Large variant="h1">{data.policy.pageName}</Title.Large>
					</div>
					<BrandLine className="w-[110%] -translate-x-10 lg:-translate-x-14 mb-12" />
					<div className="max-w-4xl mx-auto w-11/12 flex flex-col structured-wysiwyg">
						<StructuredText
							data={data.policy.policyBody}
							customNodeRules={[
								renderNodeRule(isHeading, ({ node, children, key }) => {
									const level = node.level
									return node.level <= 2 ? (
										<Title.Large key={key} variant={`h${level}`}>
											{children}
										</Title.Large>
									) : (
										<Title.Medium key={key} variant={`h${level}`}>
											{children}
										</Title.Medium>
									)
								}),
								renderNodeRule(isParagraph, ({ node, children, key }) => (
									<Text.Medium key={key}>{children}</Text.Medium>
								)),
								renderNodeRule(isList, ({ adapter, node, children, key }) => {
									return node.style === `bulleted` ? (
										<StructuredList
											{...{
												node,
												adapter,
												key,
											}}
										>
											{children}
										</StructuredList>
									) : (
										<ul className="list-number">{children}</ul>
									)
								}),
							]}
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default PolicyTemplate

export const query = graphql`
	query getPolicyPage($id: String) {
		policy: datoCmsPolicyPage(id: { eq: $id }) {
			__typename
			id
			slug
			pageName
			metadata {
				description
				title
				twitterCard
				image {
					url
				}
			}
			hero {
				cornerClip
				heroHeight
				heroType {
					... on DatoCmsHeroStatic {
						...HeroStatic
					}
					... on DatoCmsHeroCarousel {
						...HeroCarousel
					}
					... on DatoCmsHeroCharacter {
						...HeroCharacter
					}
				}
			}
			policyBody {
				blocks
				value
			}
		}
	}
`
